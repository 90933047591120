<template>
  <div class="w-100">
    <div class="title-actions">
      <h5 class="title">{{$t('tournaments')}} - {{ $tc('category', 2) | toCapitalize }}</h5>
      <div class="btns">
        <b-form-input type="search" v-model="filter" :placeholder="$t('search')" autofocus />
        <b-button variant="info" @click="add">
          <i class="fa fa-plus"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered hover :items="tournamentCategories" :fields="columns" :filter="filter">
      <template slot="actions" slot-scope="row">
        <div class="w-100 text-center">
          <b-button class="mx-2" variant="success" @click="edit(row.item)">
            <i class="fa fa-pencil"></i>
          </b-button>
          <b-button class="mx-2" variant="danger" @click="openModalDestroy(row.item)">
            <i class="fa fa-trash"></i>
          </b-button>
        </div>
      </template>
    </b-table>
    <modal-add-edit :category="category"></modal-add-edit>
    <b-modal ref="modalDelete" :title="$t('confirmTitleDelete')" headerBgVariant="danger" headerTextVariant="light">
      <p>{{$t('confirmTextDelete')}}</p>
      <strong>{{ category.name}}</strong>
      <div slot="modal-footer">
        <b-button @click="$refs.modalDelete.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="danger" @click="destroy" class="ml-3">{{$t('delete')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ModalAddEdit from './ModalAddEdit'
import { mapGetters } from 'vuex'

export default {
  components: {
    ModalAddEdit
  },
  data () {
    return {
      filter: '',
      columns: [
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'order', label: this.$t('position'), sortable: true },
        { key: 'created_at', label: this.$t('createdAgo'), sortable: false },
        { key: 'actions', label: '' }
      ],
      category: {}
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'user',
      'tournamentCategories'
    ])
  },
  mounted() {
    const title = this.$tc('category', 2)
    this.changeTitleBar(title.charAt(0).toUpperCase() + title.slice(1))
    const userSaved = localStorage.getItem('user')
    if (userSaved) {
      const user = JSON.parse(userSaved)
      if (user.type !== 'SUPER') {
        this.$router.push({ name: 'Tournaments', params: { lang: this.lang, console: this.console } })
        return
      }
    }
    if (this.tournamentCategories.length < 1) {
      this.fetchData()
    }
  },
  methods: {
    openModalDestroy (category) {
      this.category = category
      this.$refs.modalDelete.show()
    },
    add () {
      this.category = {}
      this.$root.$emit('bv::show::modal', 'modalAddEdit')
    },
    edit (category) {
      this.category = JSON.parse(JSON.stringify(category))
      this.$root.$emit('bv::show::modal', 'modalAddEdit')
    },
    fetchData (params = {}) {
      const path = `auth/${this.lang}/${this.console}/tournaments/categories`
      this.$axios.get(path, { params }).then((response) => {
        this.$store.dispatch('SET_TOURNAMENT_CATEGORIES', response.data.data)
      })
    },
    destroy () {
      const path = `auth/${this.lang}/${this.console}/tournaments/categories/${this.category.id}/delete`
      this.$axios.delete(path).then(() => {
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
        this.$store.dispatch('SET_TOURNAMENT_CATEGORIES_DELETE', this.category)
        this.$refs.modalDelete.hide()
      })
    }
  }
}
</script>
